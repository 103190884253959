<template>
    <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
        <vs-tab icon-pack="feather" icon="icon-users" :label="!isSmallerScreen ? 'Khách hàng' : ''">
            <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
                <settings-customer />
            </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-shopping-cart" :label="!isSmallerScreen ? 'Đơn hàng' : ''">
            <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
                <settings-sale />
            </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-grid" :label="!isSmallerScreen ? 'Key Tích hợp' : ''">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                <gen-key-integrate />
            </div>
        </vs-tab>
    </vs-tabs>
</template>

<script>
    import SettingsSale from "./SettingsSale"
    import SettingsCustomer from "./SettingsCustomer"
    import GenKeyIntegrate from "./GenKeyIntegrate"
    import i18n from "@/i18n";
    export default {
        components: {
            SettingsSale,
            SettingsCustomer,
            GenKeyIntegrate
        },
        data() {
            return {

            }
        },
        computed: {
            isSmallerScreen() {
                return this.$store.state.windowWidth < 768
            }
        }
    }
</script>

<style lang="scss">
    #profile-tabs {
        .vs-tabs--content {
            padding: 0;
        }
    }
</style>

<i18n>
    {
    "en":{
    "Key Tích hợp": "Key Integrate",
    "Khách hàng": "Customer",
    "Đơn hàng": "Orders"
    },
    "vi": {
    "Key Tích hợp": "Key Tích hợp",
    "Khách hàng": "Khách hàng",
    "Đơn hàng": "Đơn hàng"
    }
    }
</i18n>