import axios from 'axios';
import {SearchResult} from "@/models/SearchResult";
import {OptionItem} from "@/models/category/category";
import {ActionResult} from '@/models/ActionResult';

export class ConfigService {
    urlProduct = `${process.env.VUE_APP_PRODUCT_URL}api/v1/pricetables`;
    url = `${process.env.VUE_APP_CORE_URL}api/v1/configSystem`;

    search(keyword: string, pageSize: number):
        Promise<SearchResult<OptionItem>> {
        return axios.get(this.urlProduct, {
            params: {
                keyword: keyword,
                pageSize: pageSize
            }
        });
    }

    getConfig(): Promise<ActionResult<ConfigSystem>> {
        return axios.get(this.url);
    }

    update(configSystem: ConfigSystem):
        Promise<any> {
        return axios.post(`${this.url}`, configSystem);
    }

}

export enum VATType {
    IsNotUseVAT,
    VATInProduct,
    VATInSummary
}

export class ConfigSystem {
    commonPriceTableId: number | null;
    isAllowViewCustomerByBranch: boolean;
    vat: number = 10;
    vatType: VATType = VATType.VATInSummary;
    isAllUserUpdateOrderDiscount: boolean = true;
    allowDuplicateCustomer: boolean = false;

    constructor(commonPriceTableId: number | null, isAllowViewCustomerByBranch: boolean, vat: number, vatType: VATType, isAllUserUpdateOrderDiscount: boolean,
        allowDuplicateCustomer: boolean = false) {
        this.commonPriceTableId = commonPriceTableId;
        this.isAllowViewCustomerByBranch = isAllowViewCustomerByBranch;
        this.vat = vat;
        this.vatType = vatType;
        this.isAllUserUpdateOrderDiscount = isAllUserUpdateOrderDiscount;
        this.allowDuplicateCustomer = allowDuplicateCustomer;
    }
}