














































import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import {regex, required} from 'vee-validate/dist/rules';
import i18n from "@/i18n";
import {Component, Vue, Watch} from 'vue-property-decorator';
import vSelect from 'vue-select'
import {ConfigService, ConfigSystem, VATType} from '@/services/config/ConfigService';
import {OptionItem} from "@/models/category/category";
import each from 'lodash/each';
import find from 'lodash/find';

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

extend('regex', {
    ...regex,
    message: 'VAT phải là số'
});

@Component({
    components: {
        vSelect,
        ValidationProvider,
        ValidationObserver
    }
})

export default class SettingPage extends Vue {
    priceTables: Array<OptionItem> = [];
    debounce: any = null;
    private configService: ConfigService;
    backgroundLoading: string = 'primary';
    colorLoading: string = '#fff';
    configSystem: ConfigSystem = new ConfigSystem(null, false, 10, VATType.VATInSummary, false, false);
    invalid: boolean = false;

    vats: Array<any> = [];
    constructor() {
        super();
        this.configService = new ConfigService();
    }

    beforeMount() {
    }

    mounted() {
        //this.getPriceTables();
        this.getConfig();
    }

    changeVAT(vat: any)
    {
        each(this.vats, function (item) {
            if(item.type != vat.type)
            {
                if(!vat.id)
                    item.id = false;
                else if (vat.type == VATType.IsNotUseVAT){
                    item.id = item.type == VATType.VATInSummary;
                }
                else if (vat.type == VATType.VATInSummary){
                    item.id = item.type == VATType.VATInProduct;
                }
                else if (vat.type == VATType.VATInProduct){
                    item.id = item.type == VATType.VATInSummary;
                }
            }
        })
    }

    // async getPriceTables() {
    //     const result = await this.configService.search("", 10000);
    //
    //     if (result.code > 0) {
    //         this.priceTables = result.items;
    //     }
    // }

    async getConfig() {
        const result = await this.configService.getConfig();

        this.configSystem = result.data as ConfigSystem;

        this.vats = [{type: VATType.IsNotUseVAT, id: this.configSystem.vatType == 0, name : 'Không áp dụng VAT'},
            {type: VATType.VATInSummary, id: this.configSystem.vatType == 2, name : 'VAT hiển thị trong tổng đơn hàng'},
            {type: VATType.VATInProduct, id: this.configSystem.vatType == 1, name: 'VAT hiển thị trong từng sản phẩm của đơn hàng'}];
    }

    async save() {
        this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: "#btnSave",
            scale: 0.45
        })

        let vat = find(this.vats, function (item) {
            return item.id;
        })

        this.configSystem.vatType = vat.type;
        this.configSystem.vat = parseFloat(this.configSystem.vat.toString());
        this.invalid = true;

        const result = await this.configService.update(this.configSystem);

        this.$vs.notify({
            title: "Thông báo",
            text: result.message,
            color: result.code > 0? 'primary': 'warning',
            iconPack: 'feather',
            icon: 'icon-info'
        });

        setTimeout(() => {
            this.$vs.loading.close("#btnSave > .con-vs-loading")
            this.invalid = false;
        }, 500);
    }

    // openLoading() {
    //     this.$vs.loading()
    // }
}
