






















import {Component, Vue, Watch} from 'vue-property-decorator';
import vSelect from 'vue-select'
import {KeyIntegrateService, KeyIntegrate} from '@/services/config/KeyIntegrateService';
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

@Component({
    components: {
        vSelect
    }
})

export default class GenKeyIntegrate extends Vue {
    keys: Array<KeyIntegrate> = [];
    debounce: any = null;
    message = "Copy Key";

    private keyIntegrateService: KeyIntegrateService;

    constructor() {
        super();
        this.keyIntegrateService = new KeyIntegrateService();
    }

    beforeMount() {
    }

    mounted() {
        this.getKeys();
    }

    onError(e: any)
    {
        this.$vs.notify({
            "title" : "Đã copy",
            text: 'Key '+ e.text +" đã được copy",
            color:'success',
            position:'bottom-center',
            iconPack: 'feather',
            icon:'icon-copy'})
    }

    onCopy(e: any)
    {
        this.$vs.notify({
            "title" : "Đã copy",
            text: 'Key '+ e.text +" đã được copy",
            color:'success',
            position:'bottom-center',
            iconPack: 'feather',
            icon:'icon-copy'})
    }

    async getKeys() {
        const items = await this.keyIntegrateService.getKeys();
        this.keys = items;
    }

    async GenerateNewKy(type: number) {

        const result = await this.keyIntegrateService.genKey(type);

        this.getKeys();

        this.$vs.notify({
            text: "Đã tạo key thành công",
            color: result.code > 0? 'primary': 'warning',
            iconPack: 'feather',
            icon: 'icon-info',
            position:'top-center'
        });
    }
}
