import axios from 'axios';
import {SearchResult} from "@/models/SearchResult";
import {OptionItem} from "@/models/category/category";
import {ActionResult} from '@/models/ActionResult';

export class KeyIntegrateService {
    url = `${process.env.VUE_APP_CORE_URL}api/v1/keyintegrate`;

    getKeys(): Promise<Array<KeyIntegrate>> {
        return axios.get(this.url);
    }

    genKey(type: number):
        Promise<any> {
        return axios.post(`${this.url}/${type}`);
    }
}

export class KeyIntegrate {
    type: number;
    label: string;
    key: string;
}